const COURSE_SUBMIT_RUN = 'COURSE_SUBMIT_RUN';
const COURSE_RUN_SUBMITTING = 'COURSE_RUN_SUBMITTING';
const COURSE_SUBMITTING_SUCCESS = 'COURSE_SUBMITTING_SUCCESS';
const COURSE_SUBMITTING_FAILURE = 'COURSE_SUBMITTING_FAILURE';
const COURSE_SUBMITTING_CANCEL = 'COURSE_SUBMITTING_CANCEL';
const CLEAR_REVIEW_ALERT = 'CLEAR_REVIEW_ALERT';

export {
  COURSE_SUBMIT_RUN,
  COURSE_RUN_SUBMITTING,
  COURSE_SUBMITTING_SUCCESS,
  COURSE_SUBMITTING_FAILURE,
  COURSE_SUBMITTING_CANCEL,
  CLEAR_REVIEW_ALERT,
};
