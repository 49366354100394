export const CREATE_STAFFER = 'CREATE_STAFFER';
export const CREATE_STAFFER_SUCCESS = 'CREATE_STAFFER_SUCCESS';
export const CREATE_STAFFER_FAIL = 'CREATE_STAFFER_FAIL';
export const REQUEST_STAFFER_INFO = 'REQUEST_STAFFER_INFO';
export const REQUEST_STAFFER_INFO_SUCCESS = 'REQUEST_STAFFER_INFO_SUCCESS';
export const REQUEST_STAFFER_INFO_FAIL = 'REQUEST_STAFFER_INFO_FAIL';
export const EDIT_STAFFER_INFO = 'EDIT_STAFFER_INFO';
export const EDIT_STAFFER_INFO_SUCCESS = 'EDIT_STAFFER_INFO_SUCCESS';
export const EDIT_STAFFER_INFO_FAIL = 'EDIT_STAFFER_INFO_FAIL';
export const CANCEL_STAFFER_INFO = 'CANCEL_STAFFER_INFO';
export const RESET_STAFFER_INFO = 'RESET_STAFFER_INFO';
