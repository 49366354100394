const REQUEST_COURSE_INFO_FAIL = 'REQUEST_COURSE_INFO_FAIL';
const REQUEST_COURSE_INFO_SUCCESS = 'REQUEST_COURSE_INFO_SUCCESS';
const REQUEST_COURSE_INFO = 'REQUEST_COURSE_INFO';
const CLEAR_COURSE_INFO_ERRORS = 'CLEAR_COURSE_INFO_ERRORS';
const CREATE_COURSE = 'CREATE_COURSE';
const CREATE_COURSE_CANCEL = 'CREATE_COURSE_CANCEL';
const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
const CREATE_COURSE_FAIL = 'CREATE_COURSE_FAIL';
const EDIT_COURSE_INFO = 'EDIT_COURSE_INFO';
const EDIT_COURSE_SUCCESS = 'EDIT_COURSE_SUCCESS';
const EDIT_COURSE_FAIL = 'EDIT_COURSE_FAIL';
const CLEAR_COURSE_SAVED = 'CLEAR_COURSE_SAVED';
const CREATE_COURSE_RUN = 'CREATE_COURSE_RUN';
const CREATE_COURSE_RUN_SUCCESS = 'CREATE_COURSE_RUN_SUCCESS';
const CREATE_COURSE_RUN_FAIL = 'CREATE_COURSE_RUN_FAIL';
const CLEAR_COURSE_RUN_ALERT = 'CLEAR_COURSE_RUN_ALERT';

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

export {
  REQUEST_COURSE_INFO_FAIL,
  REQUEST_COURSE_INFO_SUCCESS,
  REQUEST_COURSE_INFO,
  CLEAR_COURSE_INFO_ERRORS,
  CREATE_COURSE,
  CREATE_COURSE_CANCEL,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAIL,
  EDIT_COURSE_INFO,
  EDIT_COURSE_SUCCESS,
  EDIT_COURSE_FAIL,
  CLEAR_COURSE_SAVED,
  CREATE_COURSE_RUN,
  CREATE_COURSE_RUN_SUCCESS,
  CREATE_COURSE_RUN_FAIL,
  CLEAR_COURSE_RUN_ALERT,
  UUID_REGEX,
};
